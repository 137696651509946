import { AtomIcon, NotebookPenIcon } from 'lucide-react';
import type { FC } from 'react';
import { Card } from '@/components/ui/card';
import { GearIcon } from '@radix-ui/react-icons';

export interface SkillsProps {}
const Skills: FC<SkillsProps> = () => {
  const features = [
    {
      title: 'Full-Stack Development',
      description:
        'With over 8 years of hands-on experience, I specialize in crafting responsive, efficient, and scalable full-stack solutions, integrating modern frontend technologies with robust backend systems.',
      icon: AtomIcon,
      languages: ['Javascript', 'Typescript', 'React.js', 'GraphQL'],
      tools: [
        'Nest.js',
        'Node.js',
        'PostgreSQL',
        'MongoDB',
        'AWS',
        'Docker',
        'Kubernetes',
      ],
    },
    {
      title: 'Technical Writing',
      description:
        'Proven technical writer with expertise in creating engaging, informative content that demystifies complex technical topics, ranging from web development frameworks to cloud infrastructure.',
      icon: NotebookPenIcon,
      languages: ['JavaScript', 'Node.js', 'React', 'TypeScript', 'AWS'],
      tools: [
        'Javascript',
        'Node.js',
        'PostgreSQL',
        'Typescript',
        'AWS',
        'Docker',
        'Kubernetes',
        'Supabase',
        'React.js',
      ],
    },
    {
      title: 'Python Automation',
      description:
        'Utilize Python’s power to automate workflows, enhance data manipulation, and support backend services, boosting efficiency and performance with tailor-made scripts and tools.',
      icon: GearIcon, // Replace with an appropriate icon for automation
      languages: ['Python'],
      tools: [
        'Flask',
        'FastAPI',
        'Pandas',
        'NumPy',
        'SQLAlchemy',
        'Celery',
        'Docker',
      ],
    },
  ];

  return (
    <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="mt-20">
        <div className="grid grid-cols-1 gap-12 lg:gap-8 sm:grid-cols-1 lg:grid-cols-3">
          {features.map((feature, index: number) => (
            <Card key={index}>
              <div className="flow-root h-full rounded-lg px-6 pb-8 shadow">
                <div className="-mt-6">
                  <div>
                    <span className="inline-flex items-center justify-center rounded-md bg-primary p-3 shadow-lg">
                      <feature.icon
                        className="h-6 w-6 text-primary-foreground"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <h3 className="mt-8 text-lg font-semibold tracking-tight">
                    {feature.title}
                  </h3>
                  <div className="text-sm mt-5 h-fit lg:h-20 min-h-fit lg:min-h-max">
                    {feature.description}
                  </div>
                  <div className="pt-6">
                    <p className="text-sm text-muted-foreground">Expertise</p>
                    <p className="mt-4">{feature?.languages.join(', ')}</p>
                  </div>
                  <div className="pt-8">
                    <p className="text-sm text-muted-foreground">
                      Tools / Frameworks
                    </p>
                    <ul className="mt-4 space-y-2">
                      {feature?.tools.map((tool, index) => (
                        <li key={index}>{tool}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
